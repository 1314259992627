import React, {Component} from 'react';

import Firebase from "./firebase";

const FirebaseContext = React.createContext(null);

export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
        { firebase => <Component {...props} firebase = {firebase} />}
    </FirebaseContext.Consumer>
);

export class FirebaseProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {firebase: null}
    }

    componentDidMount() {
        if (!this.state.firebase) {
            this.setState({firebase: new Firebase()});
        }
    }

    render() {
        return (
            <FirebaseContext.Provider value={this.state.firebase}>
                { this.props.children }
            </FirebaseContext.Provider>
        )
    }

}

export default FirebaseContext;
