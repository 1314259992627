import React, {Component} from "react";

import {injectAuth} from "./injectAuth";

class DBAuthProviderWrapper extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.props.children}
            </>
        )
    }
}

export default injectAuth(DBAuthProviderWrapper);
