import React from 'react';

import { withFirebase } from '../Firebase';

import AuthUserContext from './context';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authUser: {},
            isTeacher: false,
        };
    }

    componentDidMount() {
        this.listener = this.props.firebase.teachersAuth.onAuthStateChanged(
            authUser => {
                if (authUser) {
                    if (authUser.getIdTokenResult().claims && authUser.getIdTokenResult().claims.classCode) {
                        this.setState({
                            authUser: authUser,
                            isTeacher: false,
                        });
                    } else if (this.props.firebase.getTeacherUser(authUser)) {
                        this.setState({
                            authUser: authUser,
                            isTeacher: true,
                        });
                    } else {
                        this.props.firebase.teachersAuth.signOut();
                        this.setState({ authUser: null, isTeacher: false });
                    }
                } else {
                    this.setState({ authUser: null, isTeacher: false });
                }
            },
        );
    }

    componentWillUnmount() {
        this.listener();
    }

    render() {
        return (
            <AuthUserContext.Provider value={this.state.authUser}>
                <Component {...this.props} />
            </AuthUserContext.Provider>
        );
    }
  }
  return withFirebase(WithAuthentication);
};
export default withAuthentication;


export class AuthProvider extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          authUser: null,
          isTeacher: false,
          isSubscribed: false,
          claimInfo: null,
      };

      this.subscriptionListener = null;
  }

  componentDidMount() {
      if (this.props.firebase) {
          this.registerListener();
      }
  }

  componentDidUpdate(prevProps) {
      if (!prevProps.firebase && this.props.firebase) {
          this.registerListener();
      }
  }

  registerListener() {
      this.listener = this.props.firebase.teachersAuth.onAuthStateChanged(
          authUser => {
              if (authUser) {
                  this.props.firebase.getTeacherUser(authUser).then((doc) => {
                      authUser.getIdTokenResult().then((token) => {
                          this.setState({
                              authUser: authUser,
                              isTeacher: doc.exists,
                              isSubscribed: doc.exists && doc.data().activeSubscription,
                              claimInfo: token.claims,
                          }, () => {
                              if (doc.exists) {
                                  this.subscriptionListener = this.props.firebase.subscribeToSubscriptionChanges(authUser, (status) => this.setState({isSubscribed: status}));
                              }
                          });
                      });
                  }).catch(() => {this.signOut();});
                  /*
                  if (authUser.getIdTokenResult().claims && authUser.getIdTokenResult().claims.classCode) {
                      this.setState({
                          authUser: authUser,
                          isTeacher: false,
                      });
                  } else {
                      this.props.firebase.getTeacherUser(authUser).then((doc) => {
                          if (doc.exists) {
                              this.setState({
                                  authUser: authUser,
                                  isTeacher: true,
                              });
                          } else {
                              this.signOut();
                          }
                      }).catch(() => {this.signOut();});
                  }
                  */
              } else {
                  this.setState({ authUser: null, isTeacher: false, claimInfo: null });
              }
          },
      );
  }

  signOut() {
      this.props.firebase.teachersAuth.signOut();
      this.setState(
          { authUser: null, isTeacher: false, claimInfo: null },
          () => {
              if (this.subscriptionListener) {
                  this.subscriptionListener();
                  this.subscriptionListener = null;
              }
          });
  }

  componentWillUnmount() {
      this.listener();
      if (this.subscriptionListener) {
          this.subscriptionListener();
      }
  }

  render() {
      return (
          <AuthUserContext.Provider value={this.state.authUser === null ? null : {authUser: this.state.authUser, isTeacher: this.state.isTeacher, isSubscribed: this.state.isSubscribed, claimInfo: this.state.claimInfo}}>
              {this.props.children}
          </AuthUserContext.Provider>
      );
  }
}
