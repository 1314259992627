/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React, { Component } from "react"
import { DBAuthProviderWrapper } from "./src/components/Session2"

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.scrollTo(0,0);
  return false;
}

export const wrapPageElement = ({element, props}) => {
    return <DBAuthProviderWrapper {...props}>{element}</DBAuthProviderWrapper>;
}
