import React, { Component } from "react";

import Firebase, { FirebaseContext, FirebaseProvider,withFirebase } from "../Firebase"

import AuthUserContext from "./context";
import { AuthProvider } from "./withAuthentication";

export const injectAuth = Component => props => (
    <FirebaseProvider>
        <FirebaseContext.Consumer>
            { firebase => (
                    <AuthProvider firebase={firebase}>
                        <AuthUserContext.Consumer>
                            { authUser => <Component {...props} firebase={firebase} authUser={authUser} /> }
                        </AuthUserContext.Consumer>
                    </AuthProvider>
                )
            }
        </FirebaseContext.Consumer>
    </FirebaseProvider>
);

export const requireAuth = Component => props => (
        <FirebaseContext.Consumer>
            { firebase => (
                    <AuthUserContext.Consumer>
                        { authUser => <Component {...props} firebase={firebase} authUser={authUser} /> }
                    </AuthUserContext.Consumer>
                )
            }
        </FirebaseContext.Consumer>
);
