import app from 'firebase/app'
import {navigate} from 'gatsby'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const sat_prep_config = {
    apiKey: "AIzaSyB5TmRpSXqlt_2WJPtAf8PgdcJ9slMotH8",
    authDomain: "sat-prep-7b5d6.firebaseapp.com",
    databaseURL: "https://sat-prep-7b5d6.firebaseio.com",
    projectId: "sat-prep-7b5d6",
    storageBucket: "sat-prep-7b5d6.appspot.com",
    messagingSenderId: "713444674647",
    appId: "1:713444674647:web:d0d9953e223d5b7758a712",
    measurementId: "G-VLPD13T67P"
};

const teachers_config = {
    apiKey: "AIzaSyCn7KPoyj0aimUtwpVMhmzDVRtr5881ufo",
    authDomain: "teachers-7a724.firebaseapp.com",
    projectId: "teachers-7a724",
    storageBucket: "teachers-7a724.appspot.com",
    messagingSenderId: "1097386716107",
    appId: "1:1097386716107:web:44da8c1ae9fd1c0b3c244e",
    measurementId: "G-N53ZNMTL7F"
}

const badgeOrdering = {
    "Reading": ["Summarizing", "Reading Closely", "Multiple Texts", "Words and Phrases", "Text Structure", "Central Ideas", "Quantitative Information", "Word Choice", "Purpose", "Arguments", "Citing Textual Evidence", "Points of View", "Understanding Relationships"],
    "Math": ["Linear Equations", "Linear Inequalities", "Systems of Linear Equations", "Functions", "Scatterplots", "Statistics and Probability", "Rates, Ratios, Percents", "Polynomials & Rationals", "Quadratic Equations", "Imaginary Numbers", "Lines, Angles, Triangles", "Trigonometry", "Circles", "3D Shapes"],
    "Writing": ["Connecting Sentences", "Conventions of Usage", "Development", "Effective Language Use", "Items in a Series", "Logical Comparison", "Modifiers", "Misplaced Modifiers", "Organization", "Possessives", "Pronouns", "Punctuation", "Sentence Structure", "Usage Agreement", "Verb and Mood Shifts"]
}

class Firebase {
    constructor() {
        if (!app.apps.length) {
            app.initializeApp(sat_prep_config);
            this.teachersApp = app.initializeApp(teachers_config, "teachers");
        } else {
            this.teachersApp = app.app("teachers");
        }

        this.auth = app.auth();
        this.db = app.firestore();

        this.teachersAuth = this.teachersApp.auth();
        this.teachersDB = this.teachersApp.firestore();
        this.teachersFunctions = this.teachersApp.functions();
        this.emailAuthProvider = app.auth.EmailAuthProvider;
    }

    /********************************************************
     * SAT Prep Functions
     ********************************************************/

    doSignInAnonymously = () =>
        this.auth.signInAnonymously().catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
          });

    getAnonAccountCred = (email, password, name) => {
        this.db.collection("users_v2").doc(this.auth.currentUser.uid).update({name: name, email: email});
        var credential = app.auth.EmailAuthProvider.credential(email, password);
        return(credential);
    }

    doAnonAccountLink = (credential) => this.auth.currentUser.linkWithCredential(credential);

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doCreateNewUser = (name, email, uid, completion) => {
        var batch = this.db.batch();
        var userRef = this.db.collection("users_v2").doc(uid);
        batch.set(userRef, {
            "Math": 0,
            "Reading": 0,
            "Writing": 0,
            "days": [1, 4],
            "email": email,
            "lastAnswered": 0,
            "minutesPracticed": 0,
            "name": name,
            "notifications": true,
            "tagsCompleted": 0,
            "targetSubject": "All",
            "targetTag": "",
            "time": 16,
            "videoIndex": 0,
            "videoProgress": []
        });

        for (const [key, value] of Object.entries(badgeOrdering)) {
            for (var i=0; i<value.length; i++) {
                var badge = userRef.collection("badges").doc(value[i]);
                batch.set(badge, {
                    "tag": value[i],
                    "subject": key,
                    "lastIndex": 0
                });
            }
        }
        batch.commit().then(completion);
    }

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut().then(function(result) {
        navigate("/app/login");
    });

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    user = uid => this.db.collection("users_v2").doc(uid);

    updateUserProgress = (uid, tag, subject, newIndex, elapsedTimeMin) => {
      this.badges(uid, (badges) => {
        const userRef = this.db.collection("users_v2").doc(uid);
        const badgeRef = userRef.collection("badges").doc(tag);

        var batch = this.db.batch();
        const increment = app.firestore.FieldValue.increment(1);
        const elapsedTimeIncrement = app.firestore.FieldValue.increment(elapsedTimeMin);

        batch.update(userRef, {
            "minutesPracticed": elapsedTimeIncrement
        });

        var targetSubject = subject;
        var targetTag = tag;
        var shouldRecalculateTargetSubject = false;
        var allBadges = badges["reading"].concat(badges["math"]).concat(badges["writing"]);
        allBadges.forEach(badge => {
          if (badge.tag === tag) {
            shouldRecalculateTargetSubject = (newIndex === badge.numberOfQuestions);
          }
        });

        var readingProgress = 0;
        var mathProgress = 0;
        var writingProgress = 0;
        var readingTotalQuestions = 0;
        var mathTotalQuestions = 0;
        var writingTotalQuestions = 0;

        if (subject === "Reading") {
            readingProgress += 1;
            batch.update(userRef, {
                "Reading": increment,
            });
        } else if (subject === "Math") {
            mathProgress += 1;
            batch.update(userRef, {
                "Math": increment,
            });
        } else if (subject === "Writing") {
            writingProgress += 1;
            batch.update(userRef, {
                "Writing": increment,
            });
        }

        if (shouldRecalculateTargetSubject) {
          badges["reading"].forEach(badge => {
              readingProgress += badge.lastIndex;
              readingTotalQuestions += badge.numberOfQuestions;
          });
          badges["math"].forEach(badge => {
              mathProgress += badge.lastIndex;
              mathTotalQuestions += badge.numberOfQuestions;
          });
          badges["writing"].forEach(badge => {
              writingProgress += badge.lastIndex;
              writingTotalQuestions += badge.numberOfQuestions;
          });

          var progressRatios = [
            ["Reading", (readingProgress/readingTotalQuestions)],
            ["Math", (mathProgress/mathTotalQuestions)],
            ["Writing", (writingProgress/writingTotalQuestions)]];
          progressRatios.sort((x,y) => {return (x[1] - y[1])});
          targetSubject = progressRatios[0][0];
          targetTag = "";
        }

        batch.update(userRef, {
            "targetSubject": targetSubject,
            "targetTag": targetTag
        });

        batch.update(badgeRef, {
            "lastIndex": newIndex
        });

        batch.commit();
      });
    }

    badges = (uid, completion) => {
      this.db.collection("users_v2").doc(uid).collection("badges").get().then(function(snapshot) {
        if (snapshot.docs.length === 0) {
          this.doCreateNewUser("", "", uid, () => this.badges(uid, completion));
        } else {
          var allReading = [];
          var allWriting = [];
          var allMath = [];
          snapshot.forEach(function(doc) {
              const data = doc.data();
              if (data.subject === "Math") {
                  allMath.push(data);
              } else if (data.subject === "Reading") {
                  allReading.push(data);
              } else {
                  allWriting.push(data);
              }
          });
          allReading.sort(function(a,b) {
            return badgeOrdering["Reading"].indexOf(a.tag) - badgeOrdering["Reading"].indexOf(b.tag)
          })
          allMath.sort(function(a,b) {
            return badgeOrdering["Math"].indexOf(a.tag) - badgeOrdering["Math"].indexOf(b.tag)
          })
          allWriting.sort(function(a,b) {
            return badgeOrdering["Writing"].indexOf(a.tag) - badgeOrdering["Writing"].indexOf(b.tag)
          })

          this.db.collection("tag_metadata").get().then(function(tagMetadataSnapshot) {
            var tagMetadataDict = {}
            tagMetadataSnapshot.forEach(function(doc) {
              const data = doc.data();
              tagMetadataDict[data.tag] = data.numberOfQuestions
            })

            var reading = [];
            var completedReading = [];
            var math = [];
            var completedMath = [];
            var writing = [];
            var completedWriting = [];

            allReading.forEach(function(badge) {
              if (badge.tag in tagMetadataDict) {
                badge.numberOfQuestions = tagMetadataDict[badge.tag]
                if (badge.lastIndex == badge.numberOfQuestions) {
                  completedReading.push(badge);
                } else {
                  reading.push(badge);
                }
              }
            })
            allMath.forEach(function(badge) {
              if (badge.tag in tagMetadataDict) {
                badge.numberOfQuestions = tagMetadataDict[badge.tag]
                if (badge.lastIndex == badge.numberOfQuestions) {
                  completedMath.push(badge);
                } else {
                  math.push(badge);
                }
              }
            })
            allWriting.forEach(function(badge) {
              if (badge.tag in tagMetadataDict) {
                badge.numberOfQuestions = tagMetadataDict[badge.tag]
                if (badge.lastIndex == badge.numberOfQuestions) {
                  completedWriting.push(badge);
                } else {
                  writing.push(badge);
                }
              }
            })

            const dict = {};
            dict["reading"] = reading.concat(completedReading);
            dict["writing"] = writing.concat(completedWriting);
            dict["math"] = math.concat(completedMath);
            completion(dict)
          })
        }
      }.bind(this))
    }

    question = (index, tag, subject) => this.db.collection("questions_v4")
        .where("subject", "==", subject)
        .where("tag", "==", tag)
        .where("index", "==", index);

    sendLessonFeedback = (lesson, feedback) => {
        this.db.collection("lesson_feedback").add({
            lesson: lesson,
            feedback: feedback,
        });
    }

    sendLessonFeedback2 = (id, lesson, rating, whyBadOptions, sections, feedback) => {
        this.db.collection("lesson_feedback_v2").doc(id).set({
            lesson: lesson,
            rating: rating,
            whyBadOptions: whyBadOptions,
            sections: sections,
            feedback: feedback
        })
    }

    sendQPTracking = (id, qpTitle, numAnswered) => {
        this.db.collection("lesson_qp_tracking").doc(id).set({
            qp: qpTitle,
            numAnswered: numAnswered
        });
    }

    shareCelebration = (celebration) => {
      this.db.collection("black_history_month").add({
        text: celebration
      });
    }

    getMMUser = (uid) => {
        return this.db.collection("mm_users").doc(uid).get();
    }

    createMMUser = (uid, currentDay) => {
        this.db.collection("mm_users").doc(uid).set({
            lastVisitedDay: currentDay,
            numCompletedDays: 0,
            streak: 0,
            lastCompletedDay: 0,
            hintsGiven: 0,
            input: [],
            attempts: 0,
        });
    }

    updateMMSuccess = (uid, currentDay, challengesCompleted, streak, input) => {
        this.db.collection("mm_users").doc(uid).update({
            lastVisitedDay: currentDay,
            numCompletedDays: challengesCompleted,
            streak: streak,
            lastCompletedDay: currentDay,
            input: input,
        });
    }

    updateMMHints = (uid, currentDay, hintsGiven) => {
        this.db.collection("mm_users").doc(uid).update({
            lastVisitedDay: currentDay,
            hintsGiven: hintsGiven,
        });
    }

    updateMMAttempts = (uid, currentDay, attempts) => {
        this.db.collection("mm_users").doc(uid).update({
            lastVisitedDay: currentDay,
            attempts: attempts,
        });
    }

    createMMAnswer = (uuid, currentDay, answer) => {
        this.db.collection("mm_answers").doc(uuid).set({
            day: currentDay,
            answer: answer,
        });
    }

    logTeacherDemoEmail = (id, email) => {
        this.db.collection("teacher_demo_emails").doc(id).set({
            email: email,
        })
    }

    logOverdeckTracking = (lessonName, sessionId, metricName) => {
        this.db.collection("overdeck_tracking").doc(sessionId).set({
            lessonName: lessonName,
            [metricName]: true,
        }, {merge: true});
    }

    /********************************************************
     * Teachers Functions
     ********************************************************/

    getTeacherUser = (user) => {
        return this.teachersDB.collection("teachers").doc(user.uid).get();
    }

    createTeacherUser = (email, user) => {
        let validClassCodeChars = '0123456789abcdefghijklmnopqrstuvwxyz';
        let classCode = '';
        for (let i = 0; i < 6; i++) {
            classCode += validClassCodeChars[Math.floor(Math.random() * validClassCodeChars.length)];
        }

        let classCodeRef = this.teachersDB.collection("class_codes").doc(classCode);

        return this.teachersDB.runTransaction((transaction) => {
            return transaction.get(classCodeRef).then((doc) => {
                if (!doc.exists) {
                    transaction.set(classCodeRef, {teacherId: user.uid});
                    return true;
                }
                return false;
            })
        }).then((success) => {
            if (success) {
                return this.teachersDB.collection("teachers").doc(user.uid).set({
                    email: email,
                    classCode: classCode,
                    classCodeDisplay: classCode,
                    nextSectionId: 1,
                    sections: {},
                    activeSubscription: false,
                });
            }
            return this.createTeacherUser(email, user);
        });
    }

    doSignInWithCustomToken = (token) => {
        return this.teachersAuth.signInWithCustomToken(token);
    }

    doSubscribe = (uid, completionHandler, errorHandler) => {
        const docRef = this.teachersDB
          .collection('stripe_customers')
          .doc(uid)
          .collection('checkout_sessions')
          .add({
            price: 'price_1LxxSuGnOQmDmKxjMvH1XlEk',
            success_url: "https://www.almostfun.org/settings/?type=class",
            cancel_url: "https://www.almostfun.org/settings/?type=subscription",
            allow_promotion_codes: true,
          })
          .then((docRef) => {
            // Wait for the CheckoutSession to get attached by the extension
            docRef.onSnapshot((snap) => {
              const { error, url } = snap.data();
              if (error) {
                errorHandler(error);
              }
              if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                completionHandler(url)
              }
            });
          })
    }

    getStripePortalUrl = (user) => {
        const functionRef = this.teachersFunctions.httpsCallable('ext-firestore-stripe-payments-createPortalLink');
        return functionRef({ returnUrl: "https://www.almostfun.org/settings/?type=subscription" }).then(({data}) => data.url);
    }

    getStudents = (user, completionHandler) => {
        this.teachersDB.collection("teachers").doc(user.uid).collection("students").get().then((querySnapshot) => {
            completionHandler(querySnapshot.docs.map(x => {return { key: x.id, ...x.data() }}));
        })
    }

    setStudentDetails = (user, firstName, lastName, section) => {
        return user.getIdTokenResult().then((tokenResult) => {
            return this.teachersDB.collection("teachers").doc(tokenResult.claims.teacherId).collection("students").doc(tokenResult.claims.studentId).set({
                firstName: firstName,
                lastName: lastName,
                section: section,
            }, {merge: true});
        });
    }

    addQPData = (user, qpData) => {
        return user.getIdTokenResult().then((tokenResult) => {
            return this.teachersDB.collection("teachers").doc(tokenResult.claims.teacherId).collection("students").doc(tokenResult.claims.studentId).collection("qpProgress").add(qpData);
        });
    }

    addTeacherQPData = (user, qpData) => {
        return this.teachersDB.collection("teachers").doc(user.uid).collection("qpProgress").add(qpData);
    }

    getLastXStudentProgress = (user, qpId, student, limit, completionHandler) => {
        this.teachersDB.collection("teachers").doc(user.uid).collection("students").doc(student.key).collection("qpProgress").where("qpId", "==", Number(qpId)).orderBy("timestamp", "desc").limit(10).get().then((querySnapshot) => {
            completionHandler(querySnapshot.docs.map(x => x.data()));
        });
    }

    getStudentProgressSummary = (user, qpId, student) => {
        return this.teachersDB.collection("teachers").doc(user.uid).collection("students").doc(student.key).collection("qpSummary").doc(String(qpId)).get();
    }

    getStudentProgressToday = (user, qpId, student, completionHandler) => {
        this.teachersDB.collection("teachers").doc(user.uid).collection("students").doc(student.key).collection("qpProgress").where("qpId", "==", Number(qpId)).where("timestamp", ">=", new Date(new Date().setHours(0,0,0,0)).getTime()).get().then((querySnapshot) => {
            completionHandler(querySnapshot.docs.map(x => x.data()));
        });
    }

    getTeacherProgressSummary = (user, qpId) => {
        return this.teachersDB.collection("teachers").doc(user.uid).collection("qpSummary").doc(String(qpId)).get();
    }

    getTeacherProgressToday = (user, qpId, completionHandler) => {
        this.teachersDB.collection("teachers").doc(user.uid).collection("qpProgress").where("qpId", "==", Number(qpId)).where("timestamp", ">=", new Date(new Date().setHours(0,0,0,0)).getTime()).get().then((querySnapshot) => {
            completionHandler(querySnapshot.docs.map(x => x.data()));
        });
    }

    createNewSection = (user) => {
        let teacherRef = this.teachersDB.collection("teachers").doc(user.uid);

        return this.teachersDB.runTransaction((transaction) => {
            return transaction.get(teacherRef).then((teacherDoc) => {
                if (!teacherDoc.exists) {
                    throw "Error retrieving teacher data.";
                }

                let teacherData = teacherDoc.data();

                let nextSectionId = teacherData.nextSectionId;
                let sections = teacherData.sections;

                sections[nextSectionId] = "New Section";

                transaction.update(teacherRef, { nextSectionId: nextSectionId + 1, sections: sections });
                return nextSectionId;
            });
        });
    }

    renameSection = (user, sectionId, newSectionName) => {
        let teacherRef = this.teachersDB.collection("teachers").doc(user.uid);

        return this.teachersDB.runTransaction((transaction) => {
            return transaction.get(teacherRef).then((teacherDoc) => {
                if (!teacherDoc.exists) {
                    throw "Error retrieving teacher data.";
                }

                let teacherData = teacherDoc.data();

                let sections = teacherData.sections;

                sections[sectionId] = newSectionName;

                transaction.update(teacherRef, { sections: sections });
            });
        });
    }

    deleteStudent = (user, student) => {
        return this.teachersDB.collection("teachers").doc(user.uid).collection("students").doc(student.key).delete();
    }

    updateStudentData = (user, student, data) => {
        if (data.username) {
            let studentUsernameRef = this.teachersDB.collection("teachers").doc(user.uid).collection("student_usernames").doc(data.username);

            return this.teachersDB.runTransaction((transaction) => {
                return transaction.get(studentUsernameRef).then((usernameDoc) => {
                    if (!usernameDoc.exists) {
                        transaction.set(studentUsernameRef, {});
                        return true;
                    }
                    return false;
                }).then((success) => {
                    if (success) {
                        return this.teachersDB.collection("teachers").doc(user.uid).collection("students").doc(student.key).set(data, {merge: true}).then(() => {
                            this.teachersDB.collection("teachers").doc(user.uid).collection("student_usernames").doc(student.username);
                            return true;
                        });
                    }
                    return false;
                });
            })
        } else {
            return this.teachersDB.collection("teachers").doc(user.uid).collection("students").doc(student.key).set(data, {merge: true}).then(() => {return true});
        }
    }

    updateClassCode = (user, oldClassCode, newClassCode) => {
        let newClassCodeLC = newClassCode.toLowerCase();
        let classCodeRef = this.teachersDB.collection("class_codes").doc(newClassCodeLC);

        return this.teachersDB.runTransaction((transaction) => {
            return transaction.get(classCodeRef).then((doc) => {
                if (!doc.exists) {
                    transaction.set(classCodeRef, {teacherId: user.uid});
                    return true;
                }
                return false;
            })
        }).then((success) => {
            if (success) {
                return this.teachersDB.collection("teachers").doc(user.uid).set({classCode: newClassCodeLC, classCodeDisplay: newClassCode}, {merge: true}).then(() => {
                    this.teachersDB.collection("class_codes").doc(oldClassCode.toLowerCase()).delete();
                    return true;
                });
            }
            return false;
        });
    }

    updateTeacherProfileInfo = (user, profileInfo) => {
        return this.teachersDB.collection("teachers").doc(user.uid).set(profileInfo, {merge: true});
    }

    subscribeToSubscriptionChanges = (user, completionHandler) => {
        return this.teachersDB.collection("teachers").doc(user.uid).onSnapshot((doc) => {
            if (doc.exists) {
                completionHandler(doc.data().activeSubscription);
            } else {
                completionHandler(false);
            }
        });
    }

    sendTeacherFeedback = (id, teacherId, lesson, rating, whyBadOptions, sections, feedback) => {
        this.teachersDB.collection("lesson_plan_feedback").doc(id).set({
            teacherId: teacherId,
            lesson: lesson,
            rating: rating,
            whyBadOptions: whyBadOptions,
            sections: sections,
            feedback: feedback
        })
    }
}

export default Firebase;
